import './App.css';
import { GeneralPage } from './pages/GeneralPage/GeneralPage';
import MainPage from './pages/MainPage/MainPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import TestCreation from './pages/TestCreation/TestCreation';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
    fetchCountingTypes,
    fetchStaticDirections,
    fetchStaticTags,
    fetchTests,
} from './Redux/reducers/mainReducer';
import { fetchUser } from './Redux/actions/userActions';
import { getCookie, eraseCookie } from './helpers/cookies';

function App() {
    const dispatch = useDispatch();
    const cookies = getCookie('token');

    useEffect(() => {
        if (cookies !== null && cookies !== 'none') {
            dispatch(fetchUser());
            dispatch(fetchTests());
            dispatch(fetchCountingTypes());
            dispatch(fetchStaticTags());
            dispatch(fetchStaticDirections());
        } else {
            eraseCookie('token');
        }
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<GeneralPage />} />
                <Route path="/tests" element={<MainPage />}>
                    <Route path="published" element={<MainPage />} />
                </Route>
                <Route path="/test" element={<TestCreation />}>
                    <Route path=":testId" element={<TestCreation />}>
                        <Route path=":questions" element={<TestCreation />} />
                        <Route path=":scales" element={<TestCreation />} />
                    </Route>
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
