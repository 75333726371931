import React from 'react';

import { useSelector } from 'react-redux';
import { TestTitleInput } from 'pages/TestCreation/atoms/TestTitleInput';
import { TestMethodAuthorInput } from 'pages/TestCreation/atoms/TestMethodAuthorInput';
import { TestTextField } from 'pages/TestCreation/atoms/TestTextField';
import { DropdownAgeInput } from 'pages/TestCreation/atoms/DropdownAgeInput';
import { DropdownTags } from 'pages/TestCreation/atoms/DropdownTags';
import { DropdownDirections } from 'pages/TestCreation/atoms/DropdownDirections';

export function GeneralTestCreationRightMenu() {
    const { currentTest, staticTags, staticDirections } = useSelector((state) => state.main);

    return (
        <div className="rightMenu">
            <div className="innerCreation">
                <TestTitleInput
                    value={currentTest.name}
                    currentTestId={currentTest._id}
                    autosave={currentTest.autosave}
                />
                <TestMethodAuthorInput
                    value={currentTest.methodAuthor}
                    currentTestId={currentTest._id}
                    autosave={currentTest.autosave}
                />
                <TestTextField
                    currentTestId={currentTest._id}
                    value={currentTest.desTeacher}
                    field="desTeacher"
                    label="Описание для специалистов  (не отображается у обучающихся)"
                    autosave={currentTest.autosave}
                />
                <TestTextField
                    currentTestId={currentTest._id}
                    value={currentTest.desStudent}
                    field="desStudent"
                    label="Описание для ученика (не обязательное поле)"
                    autosave={currentTest.autosave}
                />
                <TestTextField
                    currentTestId={currentTest._id}
                    value={currentTest.desStudents}
                    field="desStudents"
                    label="Инструкция для ученика"
                    autosave={currentTest.autosave}
                />

                <div className="scaleContent ages-wrapper">
                    <div className="row">
                        <div className="ages-label">Возраст</div>
                        <DropdownAgeInput currentTest={currentTest} />
                    </div>
                </div>

                <DropdownTags
                    id="test_tags"
                    type=""
                    value=""
                    currentTest={currentTest}
                    tags={currentTest.tags}
                    staticTags={staticTags}
                    label="Добавить теги"
                />

                {staticDirections?.length && (
                    <DropdownDirections
                        currentTest={currentTest}
                        directions={currentTest.directions}
                        staticDirections={staticDirections}
                    />
                )}
            </div>
        </div>
    );
}
