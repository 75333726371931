import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import { changeLocalDirections, updateTestField } from 'Redux/reducers/mainReducer';
import { selectUpdateLocaleChanges, useTempLocalChanges } from 'store/useTempLocalChanges';
import { selectStyles } from './selectStyles';
import smallCross from '../img/smallCross.svg';

export function DropdownDirections({ currentTest, staticDirections, directions }) {
    const dispatch = useDispatch();
    const updateLocalChanges = useTempLocalChanges(selectUpdateLocaleChanges);
    const [currentDirections, setCurrentDirections] = useState([]);
    const [directionsOptions, setDirectionsOptions] = useState([]);

    useEffect(() => {
        if (staticDirections?.length) {
            const getStaticDirections = staticDirections.map(({ _id, name }) => ({
                value: _id,
                label: name,
            }));

            if (directions) {
                const filterStaticDirections = getStaticDirections.filter(({ value }) => !directions.includes(value));

                setDirectionsOptions(filterStaticDirections);
            } else {
                setDirectionsOptions(getStaticDirections);
            }
        }

        if (directions) {
            const getCurrentDirections = staticDirections.filter(({ _id }) => directions.includes(_id));
            setCurrentDirections(getCurrentDirections);
        }
    }, [staticDirections, directions]);

    function removeDirection(directionId) {
        if (currentTest.directions) {
            const updatedDirections = currentTest.directions.filter((item) => item !== directionId);
            const fields = {
                directions: updatedDirections,
            };

            dispatch(changeLocalDirections(updatedDirections));

            if (currentTest.autosave) {
                dispatch(updateTestField(currentTest._id, fields));
            } else {
                updateLocalChanges(fields);
            }
        }
    }

    function addDirectionsToTest(directionData) {
        const { value, label } = directionData;
        const updatedDirections = currentTest.directions || [];

        if (!updatedDirections.includes(value)) {
            updatedDirections.push(value);

            const fields = {
                directions: updatedDirections,
            };

            dispatch(changeLocalDirections(updatedDirections));
            setCurrentDirections([...currentDirections, { _id: value, name: label }]);

            if (currentTest.autosave) {
                dispatch(updateTestField(currentTest._id, fields));
            } else {
                updateLocalChanges(fields);
            }
        }
    }

    return (
        <div style={{ marginTop: 32 }}>
            <Select
                onChange={(e) => addDirectionsToTest(e)}
                options={directionsOptions}
                noOptionsMessage={() => 'Ничего не найдено'}
                styles={selectStyles}
                value={{ label: 'Добавить направления' }}
                components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                }}
            />
            <div className="dropdownInput dropdownTags" style={{ marginTop: 15, minHeight: 0 }}>
                {!!currentDirections.length && (
                    <div className="chips" style={{ padding: 0 }}>
                        {currentDirections.map(({ _id, name }) => (
                            <div key={_id} className="chip">
                                <img className="closer" alt="" onClick={() => removeDirection(_id)} src={smallCross} />
                                <p>{name}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
