import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    changeLocalCountingType,
    changeLocalSettingsLevel,
    copyScale,
    createIntegral,
    createScale,
    deleteScale,
    updateScaleSettings,
    updateTestField,
} from 'Redux/reducers/mainReducer';
import DropdownInput from './DropdownInput';
import DropdownTextfield from './DropdownTextfield';
import TestScale from './TestScale';
import arrowDown from '../img/arrowDown.svg';
import copyButton from '../img/copyButton.svg';
import deleteButton from '../img/deleteButton.svg';
import addButton from '../img/addTest.svg';

export function ScalesTestCreation(props) {
    function setLevelSettings(value) {
        dispatch(changeLocalSettingsLevel(value));
        let fields = {
            level: value,
        };
        dispatch(updateScaleSettings(currentTest.settings[0]._id, currentTest._id, fields));
        let currentScales = currentTest.scales;

        let defaultLevel = {
            range: {
                from: null,
                to: null,
            },
            color: null,
        };
        for (let i = 0; i < currentScales.length; i++) {
            if (currentScales[i].levels.length > value) {
                currentScales[i].levels = currentScales[i].levels.slice(0, value);
            }
            if (currentScales[i].levels.length < value) {
                let length = currentScales[i].levels.length;
                for (let j = 0; j < parseInt(value) - length; j++) {
                    currentScales[i].levels.push(defaultLevel);
                }
            }
        }
        let scales = {
            scales: currentScales,
        };

        updateTestField(currentTest._id, scales);
    }
    let dispatch = useDispatch();
    let countingTypes = useSelector((state) => state.main.countingTypes);
    let currentTest = props.currentTest;
    function openDropdown(el) {
        setTimeout(() => {
            if (el.classList.contains('opened')) {
                el.classList.remove('opened');
            } else {
                el.classList.add('opened');
            }
        }, 20);
    }
    function changeCountingType(id) {
        let fields = {
            countingMethodId: id,
        };
        dispatch(updateScaleSettings(currentTest.settings[0]._id, currentTest._id, fields));
        dispatch(changeLocalCountingType(id));
    }
    function returnCurrentCountingType() {
        let currentTypeID;
        if (currentTest.settings) {
            if (currentTest.settings[0]) {
                currentTypeID = currentTest.settings[0].countingMethodId;
            }
        }

        let name = 'Метод подсчета';
        countingTypes.map((type, typeId) => {
            if (type._id === currentTypeID) {
                name = type.name;
            }
        });
        return name;
    }
    function isThereIntegral() {
        let isTrue = false;
        if (props.currentTest) {
            if (props.currentTest.scales) {
                props.currentTest.scales.map((scale, scaleOrderId) => {
                    if (scale.integral === true) {
                        isTrue = true;
                    }
                });
            }
        }
        return isTrue;
    }
    return (
        <div className="row scales">
            <div className="leftMenu">
                <div className="checkBlock">
                    <h4>Настройка шкал</h4>
                    <p>
                        Настройки распространяться <br />
                        для всех шкал в этом тесте
                    </p>
                    <div className="levelDropdown">
                        <p>{countingTypes ? returnCurrentCountingType() : ''}</p>
                        <img src={arrowDown} />
                        <div className="levelDropdownContent onlyText">
                            {countingTypes
                                ? countingTypes.map((type, typeId) => {
                                      return (
                                          <p
                                              key={typeId}
                                              onClick={() => {
                                                  changeCountingType(type._id);
                                              }}
                                          >
                                              {type.name}
                                          </p>
                                      );
                                  })
                                : ''}
                        </div>
                    </div>
                    <div className="levelDropdown">
                        <p>
                            {currentTest.settings
                                ? currentTest.settings[0]
                                    ? currentTest.settings[0].level !== null
                                        ? currentTest.settings[0].level === 1
                                            ? currentTest.settings[0].level + ' уровень'
                                            : currentTest.settings[0].level === 5
                                            ? currentTest.settings[0].level + ' уровней'
                                            : currentTest.settings[0].level + ' уровня'
                                        : 'Выберите уровень'
                                    : 'Выберите уровень'
                                : 'Выберите уровень'}
                        </p>
                        <img src={arrowDown} />
                        <div className="levelDropdownContent onlyText">
                            <p
                                onClick={() => {
                                    setLevelSettings(1);
                                }}
                            >
                                1 уровень
                            </p>
                            <p
                                onClick={() => {
                                    setLevelSettings(2);
                                }}
                            >
                                2 уровня
                            </p>
                            <p
                                onClick={() => {
                                    setLevelSettings(3);
                                }}
                            >
                                3 уровня
                            </p>
                            <p
                                onClick={() => {
                                    setLevelSettings(4);
                                }}
                            >
                                4 уровня
                            </p>
                            <p
                                onClick={() => {
                                    setLevelSettings(5);
                                }}
                            >
                                5 уровней
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rightMenu">
                {currentTest.scales && currentTest.scales.length > 0 ? (
                    currentTest.scales.map((item, id1) => {
                        return (
                            <div key={id1} className="scalesPage">
                                <div className="scaleSettings">
                                    {!item.integral ? (
                                        <DropdownInput
                                            scaleId={item._id}
                                            id="scale_name"
                                            currentTest={currentTest}
                                            id1={id1}
                                            type="CHANGE_SCALE_NAME"
                                            value={item.name}
                                            label="Название шкалы"
                                        />
                                    ) : (
                                        <DropdownInput
                                            scaleId={item._id}
                                            id="scale_name"
                                            currentTest={currentTest}
                                            id1={id1}
                                            type="noInput"
                                            value="Интегральная"
                                            label="Название шкалы"
                                        />
                                    )}
                                    <DropdownTextfield
                                        scaleId={item._id}
                                        id="scale_desc"
                                        currentTest={currentTest}
                                        id1={id1}
                                        type="CHANGE_SCALE_DESC"
                                        value={item.description}
                                        label="Описание шкалы"
                                    />
                                </div>
                                <div className="scales">
                                    {item.levels
                                        ? item.levels.map((level, id2) => {
                                              return (
                                                  <TestScale
                                                      key={id2}
                                                      currentTest={currentTest}
                                                      scaleId={item._id}
                                                      color={level.color}
                                                      id1={id1}
                                                      id2={id2}
                                                      recommendation={level.recommendation}
                                                      interpretation={level.interpretation}
                                                      from={level.range.from}
                                                      to={level.range.to}
                                                  />
                                              );
                                          })
                                        : ''}
                                </div>
                                <div className="tools">
                                    {!item.integral ? (
                                        <div
                                            onClick={() => {
                                                dispatch(copyScale(item._id, currentTest._id, item));
                                            }}
                                            className="copyButton"
                                        >
                                            <img src={copyButton} />
                                            <p>Создать копию</p>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <div
                                        onClick={() => {
                                            dispatch(deleteScale(currentTest._id, item._id));
                                        }}
                                        className="deleteButton"
                                    >
                                        <img src={deleteButton} />
                                        <p>Удалить</p>
                                    </div>
                                </div>
                                <div
                                    onClick={(el) => {
                                        openDropdown(el.currentTarget.lastElementChild);
                                    }}
                                    className="addNewScaleButton"
                                >
                                    <img src={addButton} />
                                    <div className="addNewScaleButtonDropdown">
                                        <div onClick={() => dispatch(createScale(currentTest._id))} className="button">
                                            <div className="text">
                                                <p>Добавить шкалу</p>
                                                <label>Небольшое описание шкалы, в одном предложении</label>
                                            </div>
                                            <div className="plus">
                                                <img src={addButton} />
                                            </div>
                                        </div>
                                        {!isThereIntegral() ? (
                                            <div
                                                onClick={() => {
                                                    dispatch(createIntegral(currentTest._id));
                                                }}
                                                className="button"
                                            >
                                                <div className="text">
                                                    <p>Добавить интегральную шкалу</p>
                                                    <label>Небольшое описание шкалы, в одном предложении</label>
                                                </div>
                                                <div className="plus">
                                                    <img src={addButton} />
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div>
                        <div onClick={() => dispatch(createScale(currentTest._id))} className="addScaleButton">
                            <div className="text">
                                <h4>Добавить шкалу</h4>
                                <p>Небольшое описание шкалы, в одном предложении</p>
                            </div>
                            <div className="plusButton">
                                <img src={addButton} />
                            </div>
                        </div>
                        {!isThereIntegral() ? (
                            <div
                                onClick={() => {
                                    dispatch(createIntegral(currentTest._id));
                                }}
                                className="addScaleButton"
                            >
                                <div className="text">
                                    <h4>Добавить интегральную шкалу</h4>
                                    <p>Небольшое описание шкалы, в одном предложении</p>
                                </div>
                                <div className="plusButton">
                                    <img src={addButton} />
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
