import React from 'react';
import { useDispatch } from 'react-redux';

import {
    changeLocalSpecialistDescription,
    changeLocalStudentDescription,
    changeLocalStudentInstruction,
    updateTestField,
} from 'Redux/reducers/mainReducer';
import { selectUpdateLocaleChanges, useTempLocalChanges } from 'store/useTempLocalChanges';

const changeLocalFunctions = {
    desTeacher: changeLocalSpecialistDescription,
    desStudent: changeLocalStudentDescription,
    desStudents: changeLocalStudentInstruction,
};

export function TestTextField({ currentTestId, value, label, field, autosave }) {
    const dispatch = useDispatch();
    const updateLocalChanges = useTempLocalChanges(selectUpdateLocaleChanges);

    const handleChange = (e) => {
        const callback = changeLocalFunctions[field];

        if (callback) {
            dispatch(callback(e.target.value));
        }
    };

    const handleChangeBlur = (e) => {
        const fields = {
            [field]: e.target.value,
        };

        if (autosave) {
            dispatch(updateTestField(currentTestId, fields));
        } else {
            updateLocalChanges(fields);
        }
    };

    return (
        <div onClick={(e) => e.currentTarget.firstElementChild.focus()} className="dropdownTextfield">
            <textarea onBlur={handleChangeBlur} value={value} onChange={handleChange} placeholder=" " />
            <label>{label}</label>
        </div>
    );
}
