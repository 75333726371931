import React from 'react';
import { useDispatch } from 'react-redux';

import { changeLocalMethodAuthor, updateTestField } from 'Redux/reducers/mainReducer';
import { selectUpdateLocaleChanges, useTempLocalChanges } from 'store/useTempLocalChanges';
import infoDot from '../img/infoError.svg';

export function TestMethodAuthorInput({ currentTestId, value, autosave }) {
    const dispatch = useDispatch();
    const updateLocalChanges = useTempLocalChanges(selectUpdateLocaleChanges);

    const handleChangeValue = (e) => {
        dispatch(changeLocalMethodAuthor(e.target.value));
    };

    const handleChangeBlur = (e) => {
        const { value } = e.target;

        if (value.trim() === '') return;

        const fields = {
            methodAuthor: e.target.value,
        };

        if (autosave) {
            dispatch(updateTestField(currentTestId, fields));
        } else {
            updateLocalChanges(fields);
        }
    };

    return (
        <div
            onClick={(e) => e.currentTarget.firstElementChild.focus()}
            className="dropdownInput value"
            style={{ marginTop: 30 }}
        >
            <input
                onBlur={handleChangeBlur}
                value={value}
                onChange={handleChangeValue}
                placeholder=" "
                type="text"
                style={{ fontSize: 16 }}
            />
            <label>Авторы методики (не отображается у обучающихся)</label>
            <img className="infoDot" src={infoDot} alt="" />
            <div className="infoBlock"></div>
        </div>
    );
}
