import React, { useEffect, useState } from 'react';

import { getPoints } from 'helpers/test';
import arrowDown from '../img/arrowDown.svg';
import roundClose from '../img/roundClose.svg';
import addButton from '../img/addTest.svg';

export default function QuestionItemScales({
    currentTest,
    item,
    id1,
    changeTypeScale,
    addAdditionalScale,
    deleteAdditionalScale,
}) {
    const [scalesNames, setScalesNames] = useState({});
    const [availableScales, setAvailableScales] = useState([]);

    useEffect(() => {
        if (currentTest?.scales?.length) {
            const scales = {};
            const availableScalesTemp = [];

            for (const scale of currentTest.scales) {
                scales[scale._id] = scale.name;

                if (item.typeScale !== scale._id && !item?.additionalScales.includes(scale._id)) {
                    availableScalesTemp.push(scale);
                }
            }

            setAvailableScales(availableScalesTemp);
            setScalesNames(scales);
        }
    }, [currentTest?.scales, item]);

    return (
        <div className="scale-wrapper">
            <div className="scale-title">Шкала 1</div>
            <div className="row">
                <div className="levelDropdown questionDropdown">
                    <p style={item.typeScale ? {} : { opacity: 0.5 }}>
                        {scalesNames[item.typeScale] || 'Выберите шкалу'}
                    </p>
                    <img src={arrowDown} alt="" />
                    <div className="levelDropdownContent onlyText">
                        {availableScales.map((item2, id2) => {
                            if (!item2.integral) {
                                return (
                                    <p key={id2} onClick={() => changeTypeScale(item._id, id1, item2._id)}>
                                        {item2.name}
                                    </p>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </div>
                <div className="levelDropdown questionDropdown no-hover">
                    <p style={{ width: 164 }}>Осталось баллов</p>
                    <div className="pointsCounter">{getPoints(id1, item.typeScale, currentTest).toFixed(2)}</div>
                </div>
            </div>
            {item.questionType !== 'Range' &&
                item?.additionalScales.map((scale, i) => (
                    <React.Fragment key={scale}>
                        <div className="scale-title">Шкала {i + 2}</div>
                        <div className="row scale-row">
                            <div className="levelDropdown questionDropdown additional-scale">
                                <p style={scale ? {} : { opacity: 0.5 }}>{scalesNames[scale] || 'Выберите шкалу'}</p>
                                <img src={arrowDown} alt="" />
                                <div className="levelDropdownContent onlyText">
                                    {availableScales.map((item2, id2) => {
                                        if (!item2.integral) {
                                            return (
                                                <p
                                                    key={id2}
                                                    onClick={() => changeTypeScale(item._id, i, item2._id, item)}
                                                >
                                                    {item2.name}
                                                </p>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="levelDropdown questionDropdown scale no-hover">
                                <p>Осталось баллов</p>
                                <div className="pointsCounter">
                                    {getPoints(id1, scale, currentTest, true).toFixed(2)}
                                </div>
                            </div>

                            <div onClick={() => deleteAdditionalScale(item, i)} className="scale-remove">
                                <img src={roundClose} alt="" />
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            {item.questionType !== 'Range' && !!availableScales.length && (
                <div className="add-scale">
                    <button
                        onClick={() => addAdditionalScale(item, availableScales[0]?._id)}
                        className="add-scale__btn"
                    >
                        <div className="add-scale__btn-icon">
                            <img src={addButton} alt="" />
                        </div>
                        <span>Добавить шкалу</span>
                    </button>
                </div>
            )}
        </div>
    );
}
