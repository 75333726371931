import React from 'react';
import { useDispatch } from 'react-redux';

import { changeLocalTestName, updateTestField } from 'Redux/reducers/mainReducer';
import { selectUpdateLocaleChanges, useTempLocalChanges } from 'store/useTempLocalChanges';
import infoDot from '../img/infoError.svg';

export function TestTitleInput({ currentTestId, value, autosave }) {
    const dispatch = useDispatch();
    const updateLocalChanges = useTempLocalChanges(selectUpdateLocaleChanges);

    const handleChangeValue = (e) => {
        dispatch(changeLocalTestName(e.target.value));
    };

    const handleChangeBlur = (e) => {
        const { value } = e.target;

        if (value.trim() === '') return;

        const fields = {
            name: e.target.value,
        };

        if (autosave) {
            dispatch(updateTestField(currentTestId, fields));
        } else {
            updateLocalChanges(fields);
        }
    };

    return (
        <div onClick={(e) => e.currentTarget.firstElementChild.focus()} className="dropdownInput value">
            <input onBlur={handleChangeBlur} value={value} onChange={handleChangeValue} placeholder=" " type="text" />
            <label>Название теста</label>
            <img className="infoDot" src={infoDot} alt="" />
            <div className="infoBlock"></div>
        </div>
    );
}
