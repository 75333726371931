import React, { useEffect, useState } from 'react';

import { ReactComponent as Arrow } from 'pages/TestCreation/img/arrowLeft.svg';
import styles from './MenuQuestionsNavigator.module.css';

export const MenuQuestionsNavigator = ({ currentQuestionIndex, changeCurrentQuestionIndex, questionsLength = 0 }) => {
    const [inputValue, setInputValue] = useState(0);
    const [isInputChanged, setIsInputChanged] = useState(false);

    useEffect(() => {
        setInputValue(currentQuestionIndex + 1);
    }, [currentQuestionIndex]);

    useEffect(() => {
        if (isInputChanged) {
            if (inputValue >= 1 && inputValue <= questionsLength) {
                setIsInputChanged(false);
                changeCurrentQuestionIndex(inputValue - 1);
            }
        }
    }, [isInputChanged, inputValue, changeCurrentQuestionIndex, questionsLength]);

    const handleChangeInput = (e) => {
        const value = e.target.value;
        let updatedValue = value.replace(/\D/g, '');

        if (updatedValue > questionsLength) {
            updatedValue = questionsLength;
        }

        setIsInputChanged(true);
        setInputValue(updatedValue);
    };

    const handleBlurInput = () => {
        if (!inputValue) {
            setInputValue(currentQuestionIndex + 1);
        }
    };

    const handlePrevQuestion = () => {
        if (currentQuestionIndex > 0) {
            changeCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };
    const handleNextQuestion = () => {
        if (currentQuestionIndex < questionsLength - 1) {
            changeCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    return (
        <div className={styles.wrapper}>
            <button
                onClick={handlePrevQuestion}
                className={styles.button}
                disabled={inputValue <= 1 && inputValue !== ''}
            >
                <Arrow />
            </button>
            <input
                value={inputValue}
                onChange={handleChangeInput}
                onBlur={handleBlurInput}
                onFocus={() => setInputValue('')}
            />
            <button
                onClick={handleNextQuestion}
                className={styles.button}
                style={{ transform: 'rotate(180deg)' }}
                disabled={inputValue === questionsLength}
            >
                <Arrow />
            </button>
        </div>
    );
};
