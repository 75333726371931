import React from 'react';
import { useDispatch } from 'react-redux';

import { changeLocalQuestionValue, updateQuestion } from '../../../Redux/reducers/mainReducer';
import roundClose from '../img/roundClose.svg';

export default function QuestionAnswerValue({
    value,
    label,
    itemSelection,
    questionId,
    currentTestId,
    id1,
    id2,
    additionalScaleId = null,
}) {
    const dispatch = useDispatch();

    const handleChangeBlur = (e) => {
        const value = e.target.value || 0;
        let selection = [...itemSelection];

        if (additionalScaleId !== null) {
            selection[id2].additionalScales[additionalScaleId].value = value;
        } else {
            selection[id2].value = value;
        }

        dispatch(
            updateQuestion(questionId, currentTestId, {
                selection,
            })
        );
    };

    const handleChangeValue = (e) => {
        let value = e.target.value;

        if (!value) {
            value = 0;
        } else if (value[0] === '0') {
            value = value.slice(1);
        }

        if (additionalScaleId !== null) {
            dispatch(changeLocalQuestionValue(id1, id2, value, additionalScaleId));
        } else {
            dispatch(changeLocalQuestionValue(id1, id2, value));
        }
    };

    const removeQuestion = () => {
        const selection = [...itemSelection];
        selection.splice(id2, 1);

        dispatch(
            updateQuestion(questionId, currentTestId, {
                selection,
            })
        );
    };

    return (
        <div
            // style={
            //     props.margin === true
            //         ? props.bigField === true
            //             ? { marginTop: '30px', width: '100%' }
            //             : { marginTop: '30px' }
            //         : props.bigField === true
            //         ? { width: '100%' }
            //         : {}
            // }
            onClick={(e) => {
                e.currentTarget.firstElementChild.focus();
            }}
            className="dropdownInput value"
        >
            <input
                style={{ fontSize: '16px' }}
                onBlur={handleChangeBlur}
                value={value}
                onChange={handleChangeValue}
                placeholder=" "
                type="text"
            />
            <label>{label}</label>

            {additionalScaleId === null && (
                <div
                    onClick={() => {
                        removeQuestion();
                    }}
                    className="roundClose"
                >
                    <img src={roundClose} alt="" />
                </div>
            )}
        </div>
    );
}
