import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { startLocalQuestionsScales, updateLocalQuestionsScales } from 'Redux/reducers/mainReducer';
import { GeneralTestCreation } from './GeneralTestCreation';
import { ScalesTestCreation } from './ScalesTestCreation';
import { QuestionTestCreation } from './QuestionTestCreation';
import { SaveButton } from 'pages/TestCreation/atoms/SaveButton';

export default function CreationContent(props) {
    const dispatch = useDispatch();
    const { currentTest, isScalesLoaded } = useSelector((state) => state.main);

    useEffect(() => {
        dispatch(startLocalQuestionsScales());
    }, [dispatch, isScalesLoaded]);

    useEffect(() => {
        if (isScalesLoaded) {
            dispatch(updateLocalQuestionsScales());
        }
    }, [dispatch, currentTest.scales, isScalesLoaded]);

    document.addEventListener('click', (e) => {
        if (e.path) {
            let checker = 0;
            for (let i = 0; i < e.path.length; i++) {
                if (e.path[i].classList) {
                    if (!e.path[i].classList.contains('addNewScaleButton')) {
                        checker++;
                    }
                } else {
                    checker++;
                }
            }
            if (e.path.length === checker) {
                checker = 0;
                let toClose = document.getElementsByClassName('addNewScaleButtonDropdown');

                for (let i = 0; i < toClose.length; i++) {
                    setTimeout(() => {
                        toClose[i].classList.remove('opened');
                    }, 1);
                }
            }
        }
    });

    return (
        <div className="creationContent">
            {props.currentWindow === 0 && <GeneralTestCreation />}
            {props.currentWindow === 1 && <ScalesTestCreation currentTest={currentTest} />}
            {props.currentWindow === 2 && <QuestionTestCreation currentTest={currentTest} />}

            {currentTest && !currentTest.autosave && props.currentWindow === 0 && (
                <SaveButton currentTestId={currentTest._id} />
            )}
        </div>
    );
}
