import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CreatableSelect from 'react-select/creatable';

import { selectUpdateLocaleChanges, useTempLocalChanges } from 'store/useTempLocalChanges';
import { changeLocalTags, createStaticTag, updateTestField } from 'Redux/reducers/mainReducer';
import { showAlert } from 'Redux/actions/alertActions';
import { selectStyles } from './selectStyles';
import smallCross from '../img/smallCross.svg';

export function DropdownTags({ currentTest, tags, staticTags }) {
    const dispatch = useDispatch();
    const updateLocalChanges = useTempLocalChanges(selectUpdateLocaleChanges);
    const [tagsOptions, setTagsOptions] = useState([]);

    useEffect(() => {
        if (staticTags?.length) {
            const getStaticTags = staticTags.map(({ _id, name }) => ({
                value: _id,
                label: name,
            }));

            if (tags?.length) {
                const filterStaticTags = getStaticTags.filter(({ value }) => !tags.find(({ _id }) => _id === value));

                setTagsOptions(filterStaticTags);
            } else {
                setTagsOptions(getStaticTags);
            }
        }
    }, [staticTags, tags]);

    function removeTag(tagId) {
        if (currentTest.tags) {
            const updatedTags = currentTest.tags.filter(({ _id }) => _id !== tagId);
            const fields = {
                tags: updatedTags,
            };

            dispatch(changeLocalTags(updatedTags));

            if (currentTest.autosave) {
                dispatch(updateTestField(currentTest._id, fields));
            } else {
                updateLocalChanges(fields);
            }
        }
    }

    function addTagToTest(tagData) {
        const { value, label } = tagData;
        const updatedTags = currentTest.tags || [];

        if (!updatedTags.find(({ _id }) => value === _id)) {
            updatedTags.push({ _id: value, name: label });

            const fields = {
                tags: updatedTags,
            };

            dispatch(changeLocalTags(updatedTags));

            if (currentTest.autosave) {
                dispatch(updateTestField(currentTest._id, fields));
            } else {
                updateLocalChanges(fields);
            }
        }
    }

    function addTag(value) {
        if (value.length <= 25) {
            dispatch(createStaticTag(currentTest._id, value, currentTest.tags));
        } else {
            dispatch(
                showAlert({
                    type: 'error',
                    text: 'Длина тега должна быть менее 25 символов',
                })
            );
        }
    }

    return (
        <div style={{ marginTop: 32 }}>
            <CreatableSelect
                id="yacht_club"
                name="yacht_club"
                value={{ label: 'Добавить теги' }}
                onChange={(e) => addTagToTest(e)}
                options={tagsOptions}
                styles={selectStyles}
                components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                }}
                onCreateOption={addTag}
                formatCreateLabel={(inputValue) => `Создать "${inputValue}"`}
            />

            <div className="dropdownInput dropdownTags" style={{ marginTop: 15, minHeight: 0 }}>
                {!!tags?.length && (
                    <div className="chips" style={{ padding: 0 }}>
                        {tags.map(({ _id, name }) => {
                            if (!_id) return null;

                            return (
                                <div key={_id} className="chip">
                                    <img className="closer" alt="" onClick={() => removeTag(_id)} src={smallCross} />
                                    <p>{name}</p>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
